import Massage from "../../../assets/img/depositphotos_82514388-stock-photo-hands-massaging-female-neck.jpg";
import Acupuncture from "../../../assets/img/acupuncture_client.jpg";
import StructuralIntegration from "../../../assets/img/struct_integration.jpg";
import NeuromuscularTherapy from "../../../assets/img/Neuro-Muscular Therapy.png";
import EnergyHealing from "../../../assets/img/stack-glass-rocks-sitting-top-sandy-beach-generative-ai_899894-490.avif";

export const SERVICES = [
  {
    // Pregnancy & Fertility
    id: 0,
    image: Massage,
    type: "Massage",
    about:
      "Whether you're looking to unwind after a long week or need targeted relief from muscle tension, our skilled therapists will customize the massage to ensure you leave feeling rejuvenated and refreshed. Our specialized Pregnancy Massage is designed to support the health and well-being of both mother and baby. This gentle massage focuses on the unique needs of expectant mothers, helping to reduce stress, relieve muscle tension, and improve circulation. Our trained therapists use safe techniques to provide comfort and relaxation during this special time.Deep Tissue Massage is ideal for those seeking relief from chronic muscle pain and tension. This therapeutic massage technique targets the deeper layers of muscle and connective tissue, using slow, deliberate strokes and firm pressure to release tightness and knots. Perfect for athletes, those with physically demanding jobs, or anyone looking to address specific areas of discomfort.",
  },
  {
    id: 1,
    image: Acupuncture,
    type: "Acupuncture",
    about:
      "Our Acupuncture services offer a holistic approach to health and wellness by balancing the body's energy flow. Using fine, sterile needles inserted at specific points on the body, our experienced acupuncturists can help alleviate pain, reduce stress, and improve overall well-being. Whether you're dealing with chronic conditions or simply seeking a natural way to enhance your health, acupuncture can provide significant benefits.Fertility Acupuncture is a specialized treatment aimed at supporting reproductive health and enhancing fertility. By targeting key points associated with hormonal balance and reproductive function, our skilled practitioners work to improve blood flow to the reproductive organs and reduce stress. This gentle and effective therapy can be used alongside conventional fertility treatments or as a stand-alone option for those looking to increase their chances of conception.",
  },
  {
    id: 2,
    image: StructuralIntegration,
    type: "Structural Integration",
    about:
      "Structural Integration is a comprehensive bodywork therapy designed to realign and balance the body by focusing on the fascia, the connective tissue that surrounds muscles. This technique involves a series of sessions that systematically release tension and restore proper posture and movement patterns.",
  },
  {
    id: 3,
    image: NeuromuscularTherapy,
    type: "Neuro-Muscular Therapy",
    about:
      "Neuro-Muscular Therapy (NMT) is a precise and effective treatment aimed at addressing chronic pain and muscular imbalances. Our skilled therapists use advanced techniques to release trigger points, improve blood circulation, and restore proper muscle function. This therapy is ideal for individuals experiencing persistent pain, injury recovery, or those seeking to enhance their physical performance and overall well-being.",
  },
  {
    id: 4,
    image: EnergyHealing,
    imageClass: "energy-healing-img",
    type: "Energy Healing",
    about:
      "Energy Healing is a holistic practice that aims to balance and restore the body's energy flow for improved health and well-being. Our experienced practitioners use gentle techniques to clear blockages, align the body's energy centers (chakras), and promote deep relaxation. This non-invasive therapy can help reduce stress, enhance emotional health, and support the body's natural healing processes.",
  },
];
